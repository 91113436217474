import globalStore from '@/services/global.state';
import {BasicObject, SafeAny} from '../types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {transform, MagicBoxFrom, MagicBoxType} from '@/utils/magic-box';

export const getUUID = () => {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    const index = Math.floor(Math.random() * 0x10);
    s[i] = hexDigits.substring(index, index + 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  const index = (s[19] && 0x3) || 0x8;
  s[19] = hexDigits.substring(index, index + 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23];
  return s.join('');
};

/** 默认使用location?.href
 * 若希望在非web平台使用,必须出入search,否则会返回空 */
export const getUrlParams = (search?: string) => {
  if (globalStore.isAndroid) {
    let obj: BasicObject = {};
    if (search) {
      const index = search.indexOf('?');
      const params = search.slice(index + 1);
      let parr = params.split('&');
      for (let i of parr) {
        let arr = i.split('=');
        const key = arr[0] as string;
        obj[key] = arr[1];
      }
    }
    return obj;
  }
  if (!search) {
    search = location?.href.split('?')[1];
  }
  const params: BasicObject = {};
  const searchParams = new URLSearchParams(search).entries();
  for (const [key, value] of searchParams) {
    params[key] = value;
  }
  return params;
};

export const parseCookie = (cookieString?: string) => {
  if (!globalStore.isWeb) {
    return {};
  }
  if (!cookieString) {
    cookieString = document.cookie;
  }
  const cookieArray = cookieString.split(';');
  const cookieObject: BasicObject = {};
  cookieArray.forEach(cookie => {
    const [key, value] = cookie.trim().split('=');
    cookieObject[key] = decodeURIComponent(value);
  });
  return cookieObject;
};

export const objectToUrlParams = (obj: BasicObject) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const serializedValue =
        typeof value === 'object' ? JSON.stringify(value) : value;
      params.append(key, serializedValue);
    }
  }
  return params.toString();
};

/** 基础节流 */
export function throttle<T extends any[]>(
  fn: (...args: T) => void,
  delay: number = 200,
): (this: any, ...args: T) => void {
  let lastTime = 0;
  let timer: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: T) {
    const currentTime = Date.now();

    if (currentTime - lastTime < delay) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
        lastTime = currentTime;
      }, delay);
    } else {
      fn.apply(this, args);
      lastTime = currentTime;
    }
  };
}

/** 基础防抖 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number = 200,
): (...args: Parameters<T>) => void {
  let timerId: NodeJS.Timeout;
  return function (this: any, ...args: Parameters<T>) {
    timerId && clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

/** 会直接触发一次,然后再防抖 */
export function debounceWithImmediate<T extends (...args: any[]) => any>(
  func: T,
  delay: number = 200,
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | null = null;
  let isFirstExecution = true;

  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId!);

    if (isFirstExecution) {
      isFirstExecution = false;
      func.apply(this, args);
    }

    timeoutId = setTimeout(() => {
      isFirstExecution = true;
    }, delay);
  };
}

export const getDate = (date: Date | string | number) => {
  if (!(date instanceof Date)) {
    if (typeof date === 'number' && (date + '').length < 11) {
      date = date * 1000;
    }
    date = new Date(date);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
  }
  return date;
};

enum MonthMap {
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}

/**
 * 基础的时间格式化方法
 * @param date 需要格式化的时间,可以是字符串或者数字,如果是数字,会自动*1000
 * @param format 会将字符串中的yyyy/MM/dd/hh/mm/ss替换为具体的值
 * @returns
 */
export function formatDate(
  date: Date | string | number,
  format: string = 'yyyy-MM-dd hh:mm:ss',
): string {
  const _date = getDate(date);
  if (_date === 'Invalid Date') {
    return _date;
  }
  const year = _date.getFullYear().toString();
  const month = (_date.getMonth() + 1).toString().padStart(2, '0');
  const day = _date.getDate().toString().padStart(2, '0');
  const hours = _date.getHours();
  const isPM = hours > 11;
  const ishh = format.indexOf('hh') > -1;
  const hours_12 = isPM ? hours - 12 : hours;
  const minutes = _date.getMinutes().toString().padStart(2, '0');
  const seconds = _date.getSeconds().toString().padStart(2, '0');
  return (
    format
      .replace('yyyy', year)
      .replace('yy', year.toString().substring(2))
      .replace('MM', month)
      .replace('Mon', MonthMap[_date.getMonth()])
      .replace('dd', day)
      .replace(
        'suffix',
        day === '1' ? 'st' : day === '2' ? 'ed' : day === '3' ? 'rd' : 'th',
      )
      .replace('HH', hours.toString().padStart(2, '0'))
      .replace('hh', hours_12.toString().padStart(2, '0'))
      .replace('mm', minutes)
      .replace('ss', seconds) + (ishh ? (isPM ? ' PM' : ' AM') : '')
  );
}

/**
 * 根据天数获取时间范围
 * @param days 前几天
 * @param date 基准时间,默认今天
 */
export function getRangeDateByDays(
  days: number,
  date: Date | string | number = new Date(),
): [Date, Date] {
  const _date = getDate(date);
  if (_date === 'Invalid Date') {
    return [new Date(_date), new Date(_date)];
  }
  const endDate = new Date(_date);
  endDate.setHours(23, 59, 59, 999);
  const startDate = new Date(_date);
  startDate.setDate(startDate.getDate() - days + 1);
  startDate.setHours(0, 0, 0, 0);
  return [startDate, endDate];
}

/** 根据日期返回当前月的最后一日 */
export function getDaysByDate(date: string | number | Date): Date {
  if (!(date instanceof Date)) {
    date = new Date(date);
    if (isNaN(date.getTime())) {
      return new Date('Invalid Date');
    }
  }
  const currentMonth = date.getMonth() + 1;
  const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
  const newDate = new Date();
  newDate.setFullYear(date.getFullYear());
  newDate.setMonth(nextMonth - 1);
  newDate.setDate(0);
  return newDate;
}

/**
 * 将列表转换为分组列表
 * @param list 源列表
 * @param groupCount 一组有多少个
 */
export function getSeperateList<T>(list: T[], groupCount: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < list.length; i += groupCount) {
    result.push(list.slice(i, i + groupCount));
  }
  return result;
}

/**
 * 在使用Promise.allSettled后,对useState进行赋值
 * @param setFun set方法
 * @param result promise返回的result
 * @param nullData 无值时的填充,默认为[],可按需传入其他数据
 */
export const setDataForSettled = <T, D>(
  setFun: React.Dispatch<React.SetStateAction<T | D>>,
  result: PromiseSettledResult<T>,
  nullData: D = [] as D,
) => {
  setFun(result.status === 'fulfilled' ? result.value || nullData : nullData);
};

/**
 * 字符串加密
 * @param inputString 字符串
 * @returns 返回加密字符，只显示最后一段
 */
export const formatNumberGroup = (inputString?: string | '') => {
  if (inputString) {
    let formattedString = inputString!.replace(/(\d{4})/g, '$1 ');
    const strArr = formattedString.split(' ');
    const fist = strArr.slice(0, strArr.length - 1);
    const last = strArr.slice(-1);
    const firstStr = fist.map((item: string) => '*'.repeat(item.length));
    return firstStr.concat(last).join(' ');
  }
  return '';
};

/**
 *  英文转为首字母大写
 * @param inputString
 * @returns
 */
export const capitalizeWords = (inputString: string) => {
  const words = inputString.split(' ');
  const capitalizedWords = words.map(word => {
    // 将单词的首字母转换为大写，其余字母转换为小写
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const resultString = capitalizedWords.join(' ');
  return resultString;
};

export const errorLog = (...args: SafeAny[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(args);
  }
};

/** 前位补零 */
export const fillZero = (num?: number | `${number}`, minLength = 2) => {
  if (!num || Number(num) < 0) {
    return new Array(minLength).fill(0).join('');
  }
  if (typeof num === 'number') {
    (num as number | string) += '';
  }
  return new Array(minLength - (num as string).length).fill(0).join('') + num;
};

export type TimeData<T extends number | string> = {
  seconds: T;
  minutes: T;
  hours: T;
  days: T;
};
export function getHMSbyTimer(timer: number): TimeData<number>;
export function getHMSbyTimer(
  timer: number,
  needFillZero: false,
): TimeData<number>;
export function getHMSbyTimer(
  timer: number,
  needFillZero: true,
): TimeData<string>;
/** 根据毫秒数获取时分秒
 * @param timer 毫秒数
 * @param needFillZero 是否需要补零, 传入后,返回值会变成字符串类型
 */
export function getHMSbyTimer(timer: number, needFillZero?: boolean) {
  const seconds = Math.floor((timer / 1000) % 60),
    minutes = Math.floor((timer / (1000 * 60)) % 60),
    hours = Math.floor((timer / (1000 * 60 * 60)) % 24),
    days = Math.floor(timer / (1000 * 60 * 60 * 24));
  if (needFillZero) {
    return {
      seconds: fillZero(seconds),
      minutes: fillZero(minutes),
      hours: fillZero(hours),
      days: days + '',
    };
  } else {
    return {
      seconds,
      minutes,
      hours,
      days,
    };
  }
}

export const padZero = (num: string | number, length: number) => {
  return num.toString().padStart(length, '0');
};

export const groupArrayByLength = (arr: SafeAny[], groupSize = 5) => {
  const groups = [] as SafeAny[][];
  for (let i = 0; i < arr.length; i += groupSize) {
    groups.push(arr.slice(i, i + groupSize));
  }
  return groups;
};

export function generateRandomStr(count: number) {
  const strArr = [
    ...Array(10)
      .fill(0)
      .map((_, i) => String.fromCharCode(i + '0'.charCodeAt(0))),
    ...Array(26)
      .fill(0)
      .map((_, i) => String.fromCharCode(i + 'a'.charCodeAt(0))),
    ...Array(26)
      .fill(0)
      .map((_, i) => String.fromCharCode(i + 'A'.charCodeAt(0))),
  ];
  let resultStr = '';
  for (let i = 0; i < count; i++) {
    resultStr += strArr[Math.floor(Math.random() * strArr.length)];
  }
  return resultStr;
}

export const saveDigitType = async (list: {label: string; value: number}[]) => {
  await AsyncStorage.setItem('DIGIT_TYPE', JSON.stringify(list));
};

export const getDigitType = async () => {
  const res = await AsyncStorage.getItem('DIGIT_TYPE');
  if (res) {
    return JSON.parse(res) as {label: string; value: number}[];
  }
  return [];
};

interface keyString {
  [key: string]: string | number;
}

interface IRes extends keyString {
  gameIconUrl: string;
  gameName: string;
  issNo: string;
  openTime: number;
  totalAmount: number;
  totalAwardAmount: number;
}

const getRes = (
  changeList: [string, string][] | [],
  rawData?: SafeAny,
  gameType?: MagicBoxType,
): IRes => {
  const list: (string | [string, string])[] = [
    'gameIconUrl',
    'gameName',
    'issNo',
    'openTime',
    'totalAmount',
    'totalAwardAmount',
  ];
  const baseObj = [...list, ...changeList].reduce(
    (res, cur: [string, string] | string) => {
      if (Array.isArray(cur)) {
        res[cur[0]] =
          cur[1] === 'drawSec' ? rawData?.[cur[1]] * 1000 : rawData?.[cur[1]];
        if (
          cur[1] === 'lotteryName' &&
          rawData?.[cur[1]] &&
          ['quickStateLottery'].includes(gameType || '')
        ) {
          res[cur[0]] = `${rawData?.[cur[1]]}-${rawData?.tabMin}min`;
        }
        if (
          [
            '3Digit',
            'quick3d',
            'pick3',
            'stateLottery',
            'quickStateLottery',
          ].includes(gameType!) &&
          cur[0] === 'openTime'
        ) {
          res[cur[0]] = Date.now();
        }
      } else {
        res[cur] = rawData?.[cur];
        if (
          cur === 'totalAmount' &&
          ['stateLottery', 'quickStateLottery'].includes(gameType || '')
        ) {
          res[cur] = (rawData?.ticketsLists || rawData?.tickets || []).reduce(
            (p: number, c: SafeAny) => p + c.amount + (c.fee || 0),
            0,
          );
        }
        if (
          cur === 'gameName' &&
          rawData?.[cur] &&
          ['quickStateLottery'].includes(gameType || '')
        ) {
          res[cur] = `${rawData?.[cur]}-${rawData?.tabMin}min`;
        }
      }
      return res;
    },
    {} as IRes,
  );
  return gameType === 'scratch'
    ? {...baseObj, count: rawData.orderList.length}
    : baseObj;
};

interface IIMUIData {
  [key: string]: SafeAny;
}

const typeMapList = {
  dice: [],
  kerala: [
    ['issNo', 'issueNo'],
    ['openTime', 'drawDate'],
    ['totalAwardAmount', 'wonAmount'],
  ],
  '3Digit': [
    ['issNo', 'orderGroup'],
    ['openTime', 'gameDrawTime'],
    ['totalAwardAmount', 'winAmount'],
  ],
  quick3d: [
    ['gameIconUrl', 'gameIcon'],
    ['issNo', 'orderGroup'],
    ['openTime', 'gameDrawTime'],
    ['totalAwardAmount', 'winAmount'],
  ],
  color: [
    ['issNo', 'issueNo'],
    ['totalAwardAmount', 'awardAmount'],
  ],
  quickRace: [
    ['gameIconUrl', 'gameIcon'],
    ['issNo', 'orderId'],
    ['totalAmount', 'orderAmount'],
    ['totalAwardAmount', 'orderWinAmount'],
  ],
  matka: [
    ['issNo', 'orderGroup'],
    ['openTime', 'drawDate'],
    ['totalAwardAmount', 'wonAmount'],
  ],
  scratch: [
    ['gameIconUrl', 'gameIcon'],
    ['issNo', 'orderId'],
    ['totalAmount', 'orderAmount'],
    ['totalAwardAmount', 'orderWinAmount'],
  ],
  casino: [
    ['gameIconUrl', 'gameIcon'],
    ['issNo', 'orderId'],
    ['totalAmount', 'orderAmount'],
    ['totalAwardAmount', 'orderWinAmount'],
  ],
  live: [
    ['gameIconUrl', 'gameIcon'],
    ['issNo', 'orderId'],
    ['totalAmount', 'orderAmount'],
    ['totalAwardAmount', 'orderWinAmount'],
  ],
  sport: [
    ['gameIconUrl', 'gameIcon'],
    ['issNo', 'orderId'],
    ['totalAmount', 'orderAmount'],
    ['totalAwardAmount', 'orderWinAmount'],
  ],
  stateLottery: [
    ['gameIconUrl', 'icon'],
    ['gameName', 'lotteryName'],
    ['issNo', 'orderGroup'],
    ['openTime', 'drawSec'],
    ['totalAwardAmount', 'totalPrize'],
  ],
  quickStateLottery: [
    ['gameIconUrl', 'icon'],
    ['gameName', 'lotteryName'],
    ['issNo', 'orderGroup'],
    ['openTime', 'drawSec'],
    ['totalAwardAmount', 'totalPrize'],
  ],
};

export const transfor = (
  rawData: SafeAny,
  gameType: MagicBoxType,
  {
    rest,
    from,
  }: {
    rest?: IIMUIData;
    from?: MagicBoxFrom;
  },
) => {
  const res: {[key: string]: SafeAny} = {
    rawData,
    list: transform(rawData, gameType, from),
    gameType,
  };
  if (rest) {
    return {...res, ...rest};
  }
  // @ts-ignore
  return {...res, ...getRes(typeMapList[gameType], rawData, gameType)};
};
